<template>
  <div class="lb-top-menu-wrapper" id="topNavigation">
    <div class="lb-top-menu">
      <ul role="list" class="text-center justify-between items-center hidden sm:flex space-x-8">
        <li v-for="(item, index) in topMenu" :key="`ics-101${index}`">
          <NuxtLinkLocale :to="getLocaleString(item.href)" class="flex items-center justify-center text-center flex-wrap">
            <NuxtImg :src="item.custom?.image" height="16" width="16" class="mr-1.5" v-if="item.custom?.image" />
            <span>{{getLocaleString(item.name)}}</span>
          </NuxtLinkLocale>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { isB2BAccount, topMenu } = storeToRefs(storeConfig)
</script>
